<template>
  <item-wrapper id="returns">
    <item-header :title="t('returns.title')" />
    <main>
      <div class="returns-content-one">
        <h4>{{ t("returns.p1") }}</h4>
        <p>{{ t("returns.content1") }}</p>
      </div>
      <div class="returns-content-two">
        <h4>{{ t("returns.p2") }}</h4>
        <p v-html="t('returns.content2', { email: 'info@rightphone.com' })" />
        <p>{{ t("returns.p4") }}</p>
        <p>{{ t("returns.p5") }}</p>
        <p>{{ t("returns.p6") }}</p>
        <p>{{ t("returns.p7") }}</p>
        <p>{{ t("returns.p8") }}</p>
        <p>{{ t("returns.p9") }}</p>
        <p>{{ t("returns.p10") }}</p>
        <p>{{ t("returns.p11") }}</p>
        <p>{{ t("returns.p12") }}</p>
        <h5 v-html="t('returns.content3')" />
      </div>
      <div class="returns-content-three">
        <h4>{{ t("returns.p13") }}</h4>
        <p>{{ t("returns.content4") }}</p>
        <p>{{ t("returns.content5") }}</p>
      </div>
      <div class="returns-content-four">
        <h4>{{ t("returns.p14") }}</h4>
        <p>{{ t("returns.list1") }}</p>
        <p>{{ t("returns.list2") }}</p>
        <p>{{ t("returns.list3") }}</p>
        <p>{{ t("returns.list4") }}</p>
        <h5 v-html="t('returns.list5')"></h5>
      </div>
      <div class="returns-content-five">
        <h4>{{ t("returns.p15") }}</h4>
        <p v-html="t('returns.content6')" />
      </div>
    </main>
  </item-wrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ReturnsInformation',
  components: {
    ItemHeader,
    ItemWrapper,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

main {
  @include flex-center();
  flex-direction: column;
  align-items: baseline;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 61px;
  h4 {
    font-size: 18px;
    font-weight: 800;
    color: #1c1c1c;
    line-height: 35px;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    line-height: 35px;
    margin-bottom: 0;
  }
  .returns-content-one {
    p {
      margin-bottom: 20px;
    }
  }
  .returns-content-two {
    margin-bottom: 20px;
    h5 {
      margin-top: 20px;
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      line-height: 35px;
    }
    :deep(a){
      color:#0000ee;
      &:active{
        color:#FF0000
      }
    }
  }
  .returns-content-three {
    margin-bottom: 20px;
  }
  .returns-content-four {
    margin-bottom: 20px;
    h5 {
      font-size: 18px;
      font-weight: 400;
      color: #545454;
      line-height: 35px;
      margin-top: 20px;
    }
  }
  .returns-content-five {
    p {
      margin-bottom: 20px;
    }
  }
}
</style>
